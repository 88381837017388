import React from 'react';
import ContactForm from './ContactForm';
import FAQ from './Faq';

const Start = ({ activeSection }) => {
  return (
    <section id="start" className='section fade-in'>
      <div className='section-content'>
        <div className='two-columns'>
          <div className='column'>
            <FAQ />
          </div>
          <div className='column'>
            <div className='contact-form-container'>
              <div className='section-heading heading-left'>
                <h2 className={`${activeSection === 'start' ? 'heading-active' : ''}`}>
                  Online Form
                </h2>
              </div>
              <p className='contact-form-text'>Fill out the form below to get started.</p>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Start;

