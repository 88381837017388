import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import heroImage from '../assets/images/website-examples.png';
import shopifyPartner from '../assets/images/shopify-partner.png';

const Hero = ({ activeSection, setActiveSection, handleNavigation }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const rootStyle = window.getComputedStyle(document.documentElement);
    const offsetValue = parseInt(rootStyle.getPropertyValue('--header-height').trim()) * -1;
    setOffset(offsetValue);
  }, []);

  return (
    <section id="hero" className='section fade-in'>
      <div className='section-content'>
        <div className='two-columns'>
          <div className='column'>
            <div className='shopify-partner'>
              <img src={shopifyPartner} alt='Shopify Partners' />
            </div>
            <h1>Shopify Experts</h1>
            <p>Shopify strategy and development services tailored to your business needs.</p>
            <div className='hero-btn-container'>
              <RouterLink to="/" onClick={() => handleNavigation('contact', offset)}>
                <button className="btn-retro btn-colored">
                  Contact Us
                </button>
              </RouterLink>
              <RouterLink to="/" onClick={() => handleNavigation('services', offset)}>
                <button className="btn-text link">
                  Learn More
                </button>
              </RouterLink>
            </div>
          </div>
          <div className='column'>
            <div className='hero-image'>
              <img src={heroImage} alt='Website Example' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero;


