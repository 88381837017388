import React, { useEffect } from 'react';
import '../index.css';
import { Link as RouterLink } from 'react-router-dom';
import MobileMenu from './MobileMenu';

const Header = ({ isSticky, toggleMobileMenu, isMobileMenuVisible, activeSection, setActiveSection, handleNavigation, offset }) => {
  
  useEffect(() => {
    if (isMobileMenuVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isMobileMenuVisible]);

  const zIndexHeader = {
    zIndex: '3000'
  };

  return (
    <header className={`header fade-in ${isSticky ? 'sticky' : ''}`} style={zIndexHeader}>
      <div className="header-content">
        <RouterLink to="/" onClick={() => handleNavigation('hero', offset, true)}>
          <div className='header-logo-container'>
            <h3>shopstrat_</h3>
          </div>
        </RouterLink>
        <ul className="header-nav">
          <RouterLink to="/" onClick={() => handleNavigation('about', offset)}>
            <li className={`link ${activeSection === 'about' ? 'active' : ''}`}>About</li>
          </RouterLink>
          <RouterLink to="/" onClick={() => handleNavigation('services', offset)}>
            <li className={`link ${activeSection === 'services' ? 'active' : ''}`}>Services</li>
          </RouterLink>
          {/* <RouterLink to="/" onClick={() => handleNavigation('partners', offset)}>
            <li className={`link ${activeSection === 'partners' ? 'active' : ''}`}>Partners</li>
          </RouterLink> */}
          <RouterLink to="/" onClick={() => handleNavigation('contact', offset)}>
            <li className={`link ${activeSection === 'contact' ? 'active' : ''}`}>Contact</li>
          </RouterLink>
          <RouterLink to="/" onClick={() => handleNavigation('faq', offset)}>
            <li className={`link ${activeSection === 'faq' ? 'active' : ''}`}>FAQ</li>
          </RouterLink>
        </ul>
        <RouterLink to="/" onClick={() => handleNavigation('start', offset)}>
          <button className="btn-retro btn-colored header-cta-button">Start Here</button>
        </RouterLink>
        <button
          className="icon-btn mobile-menu-btn"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuVisible ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
        </button>
      </div>
      {isMobileMenuVisible && (
        <MobileMenu
          toggleMobileMenu={toggleMobileMenu}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          handleNavigation={handleNavigation}
          offset={offset}
        />
      )}
    </header>
  );
};

export default Header;








