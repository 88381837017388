import React, { useState, useEffect } from 'react';
import '../index.css';

const services = [
  {
    name: 'Shopify Training',
    icon: 'fas fa-chalkboard-teacher',
    description: 'Expert training in Shopify backend, theme customization, and best practices.',
    longDescription: 'We provide comprehensive Shopify training sessions covering the admin backend, theme editor for frontend customization, app integrations, and overall best practices. Whether through live sessions, on-demand videos, or personalized coaching, our training equips you and your team with the skills to maximize your Shopify store\'s potential.'
  },
  {
    name: 'Research & Analysis',
    icon: 'fas fa-user-friends',
    description: 'In-depth market research and competitor insights with detailed reports.',
    longDescription: 'We conduct comprehensive market research using surveys, interviews, and secondary data to uncover key industry trends and customer insights. Our competitor analysis includes competitive benchmarking, SWOT analysis, and online presence evaluation. We present our findings in detailed reports providing actionable recommendations to help you navigate your market effectively.'
  },
  {
    name: 'Conversion Optimization',
    icon: 'fas fa-percentage',
    description: 'Boost your site\'s conversions with expert website audits and A/B testing.',
    longDescription: 'Our Conversion Optimization services begin with an in-depth website audit, focusing on user experience, content effectiveness, and technical performance. We provide actionable recommendations and implement A/B testing, conversion funnel optimization, and personalization strategies to enhance your website’s conversion rates. Our continuous monitoring and reporting ensure that your site consistently performs at its best.'
  },
  {
    name: 'UI/UX Improvements',
    icon: 'fas fa-pencil-ruler',
    description: 'Enhance site design and user experience for better engagement.',
    longDescription: 'Our UI/UX Improvement services focus on creating a seamless and engaging experience for your website visitors. We enhance the visual design and responsiveness of your site, ensure accessibility, and optimize user flows. Through usability testing and continuous feedback loops, we refine interactive elements and measure the impact of our improvements, driving better engagement and satisfaction.'
  },
  {
    name: 'New Stores & Migrations',
    icon: 'fas fa-rocket',
    description: 'Build or migrate stores with minimal downtime and full optimization.',
    longDescription: 'We specialize in building custom, fully optimized Shopify stores that reflect your brand and business goals. Our services include comprehensive platform setup, SEO, and marketing integration. For migrations, we ensure a seamless transfer of all data, including products and customer information, with minimal downtime and risk. We provide post-launch support and ongoing maintenance to keep your site running smoothly and securely.'
  },
  {
    name: 'Apps and 3PLs',
    icon: 'fas fa-plug',
    description: 'Seamless integration of apps and logistics for efficient operations.',
    longDescription: 'We specialize in integrating third-party applications to enhance your store’s functionality, including ecommerce tools, analytics, and automation. Our services also include integrating third-party logistics (3PL) providers to streamline warehousing, fulfillment, and shipping operations. These integrations improve operational efficiency, reduce costs, and enhance the overall customer experience, providing scalable solutions that adapt to your business needs.'
  }
];

const Services = ({ activeSection }) => {
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    if (selectedService) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [selectedService]);

  const openPopup = (service) => {
    setSelectedService(service);
  };

  const closePopup = () => {
    setSelectedService(null);
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains('popup')) {
      closePopup();
    }
  };

  return (
    <>
      <section id="services" className="section fade-in">
        <div className="section-content">
          <div className="section-heading heading-center">
            <h2 className={`${activeSection === 'services' ? 'heading-active' : ''}`}>
              What We Do
            </h2>
          </div>
          <div className="services-grid">
            {services.map((service, index) => (
              <div key={index} className="service-card" onClick={() => openPopup(service)}>
                <div className='service-card-title'>
                  <i className={service.icon}></i>
                  <h5>{service.name}</h5>
                </div>
                <p>{service.description}</p>
                <button className='icon-btn service-card-plus-button'>
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
      {selectedService && (
        <div className="popup" onClick={handleClickOutside}>
          <div className="popup-content">
            <button className="icon-btn popup-close-button" onClick={closePopup}>
              <i className="fas fa-times"></i>
            </button>
            <div className='popup-header'>
              <h3>{selectedService.name}</h3>
            </div>
            <p>{selectedService.longDescription}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;