import { useNavigate } from 'react-router-dom';

const NavigationHandler = ({ setActiveSection, children }) => {
  const navigate = useNavigate();

  const handleNavigation = (section, offset, noOffset = false) => {
    navigate('/'); // Ensure navigation to the home route
    setTimeout(() => {
      setActiveSection(''); // Reset activeSection before setting the new one
      setTimeout(() => {
        setActiveSection(section);
        const element = document.getElementById(section);
        if (element) {
          window.scrollTo({
            top: noOffset ? 0 : element.offsetTop + offset,
            behavior: 'smooth'
          });
        }
      }, 100); // Delay for resetting activeSection
    }, 100);
  };

  return children(handleNavigation);
};

export default NavigationHandler;


