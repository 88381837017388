import React, { useState, useRef } from 'react';

const Faq = ({ activeSection }) => {
  const [activeIndices, setActiveIndices] = useState([]);
  const [allOpen, setAllOpen] = useState(false);
  const contentRef = useRef([]);

  const faqData = [
    {
      question: "What Shopify plans do you recommend?",
      answer: "Choosing the right Shopify plan can be overwhelming. We’ll assess your business needs, sales volume, and budget to recommend the optimal plan. Whether you're a startup or an established brand, we'll help you select the plan that maximizes your return on investment." 
    },
    {
      question: "How long does it take to build a Shopify store?",
      answer: "The timeline for building a Shopify store varies based on complexity. A basic store can be launched within a few weeks, while more complex stores with custom features may take longer. We’ll provide a detailed project timeline during our initial consultation."
    },
    {
      question: "How much does Shopify development cost?",
      answer: "The cost of Shopify development depends on several factors, including store complexity, design customization, and the number of integrations. We offer transparent pricing and provide customized quotes based on your specific requirements."
    },
    {
      question: "Do you offer Shopify migration services?",
      answer: "Yes, we specialize in Shopify migrations. Whether you're moving from another platform or upgrading your existing Shopify store, our team can seamlessly transfer your products, customers, and data."
    },
    {
      question: "What kind of integrations can you handle?",
      answer: "We can integrate a variety of apps and services to enhance your Shopify store's functionality. From shipping and payment gateways to marketing and customer relationship management tools, we can help you find the right solutions to meet your business needs."
    },
    {
      question: "How do payments work with Shopify and how secure are they?",
      answer: "Shopify offers a variety of secure payment gateways for your store. We can help you choose the right one for your business needs. All transactions are processed through secure servers with industry-standard encryption to protect your customers' financial information."
    },
    {
      question: "What is your refund policy?",
      answer: "We are committed to your satisfaction. You may be eligible for a refund within 7 days of service delivery if you are dissatisfied for reasonable reasons or if services are not delivered. To request a refund, please contact us at info@shopstrat.dev within the specified timeframe."
    }
  ];

  const toggleAccordion = (index) => {
    const newActiveIndices = [...activeIndices];
    if (newActiveIndices.includes(index)) {
      newActiveIndices.splice(newActiveIndices.indexOf(index), 1);
    } else {
      newActiveIndices.push(index);
    }
    setActiveIndices(newActiveIndices);
  };

  const toggleAll = () => {
    if (allOpen) {
      setActiveIndices([]);
    } else {
      setActiveIndices(Array.from({ length: faqData.length }, (_, i) => i));
    }
    setAllOpen(!allOpen);
  };

  return (
    <section id="faq" className='section fade-in'>
      <div className='faq-container'>
        <div className='faq-heading-row'>
          <div className='section-heading'>
            <h2 className={`${activeSection === 'faq' ? 'heading-active' : ''}`}>
              FAQ
            </h2>
          </div>
          <div className='faq-toggle-group'>
            <button className="faq-toggle-text" onClick={toggleAll}>
              {allOpen ? 'Collapse All' : 'Expand All'}
            </button>
            <div className='faq-toggle-container'>
              <input type="checkbox" id="switch" checked={allOpen} onChange={toggleAll} />
              <label htmlFor="switch">Toggle</label>
            </div>
          </div>
        </div>
        <div className="faq-container">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div
                className={`faq-question ${activeIndices.includes(index) ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                <p>{item.question}</p>
                <button className='icon-btn faq-icon'>
                  {activeIndices.includes(index) ? <i className='fas fa-times' /> : <i className='fas fa-plus' />}
                </button>
              </div>
              <div 
                ref={el => contentRef.current[index] = el}
                className={`faq-answer ${activeIndices.includes(index) ? 'active' : ''}`}
                style={{
                  maxHeight: activeIndices.includes(index) ? `${contentRef.current[index]?.scrollHeight}px` : '0',
                }}
              >
                <p className="faq-answer-content">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;