import React, { useEffect } from 'react';

function PrivacyStatement() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="privacy" className="page fade-in">
      <div className='section-content'>
        <h2>Privacy Statement</h2>
        <p>Last Updated: July 8th, 2024</p>
        <p>
          Shopstrat Development ("we", "our", "us") is committed to protecting and respecting your privacy. This Privacy Statement outlines how we collect, use, and protect your personal information when you use our website.
        </p>
        <h4>1. Information We Collect</h4>
        <p>
          We collect the following personal information when you submit the online form or subscribe to our email newsletter:
        </p>
        <ul>
          <li> - Name</li>
          <li> - Email address</li>
        </ul>
        
        <h4>2. Purpose of Data Collection</h4>
        <p>
          We collect your personal information for the following purposes:
        </p>
        <ul>
          <li> - To provide our services to you.</li>
          <li> - To send marketing communications, such as our email newsletter.</li>
        </ul>
        
        <h4>3. Data Sharing</h4>
        <p>
          We do not share your personal data with any third parties.
        </p>
        
        <h4>4. Data Protection Measures</h4>
        <p>
          We implement various security measures to protect your personal information. Our website is built using React and is hosted on a secure cPanel server. We use industry-standard encryption to ensure the safety of your data.
        </p>
        
        <h4>5. User Rights</h4>
        <p>
          You have the right to access, correct, or delete your personal information. If you wish to exercise any of these rights, please contact us at <a href="mailto:info@shopstrat.dev">info@shopstrat.dev</a>.
        </p>
        
        <h4>6. Cookies and Tracking</h4>
        <p>
          We do not use cookies or other tracking technologies on our website.
        </p>
        
        <h4>7. Changes to This Privacy Statement</h4>
        <p>
          We may update this Privacy Statement from time to time. The latest version will always be available on this page, and the date of the last update will be indicated at the top of the statement.
        </p>
        
        <h4>8. Children's Privacy</h4>
        <p>
          Our services are not intended for children. We do not knowingly collect personal information from children. If we become aware that we have inadvertently received personal information from a child, we will delete such information from our records.
        </p>
        
        <h4>9. Contact Us</h4>
        <p>
          If you have any questions or concerns about this Privacy Statement, please contact us at:
        </p>
        <p>
          Shopstrat Development<br />
          PO Box 50026, 15-1594 Fairfield Rd<br />
          Victoria, BC V8S 1G0<br />
          <a href="mailto:info@shopstrat.dev">info@shopstrat.dev</a>
        </p>
      </div>
    </section>
  );
}

export default PrivacyStatement;

