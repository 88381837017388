import React, { useEffect } from 'react';

function TermsOfUse() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="terms" className="page fade-in">
      <div className='section-content'>
        <h2>Terms of Use</h2>
        <p>Last Updated: July 8th, 2024</p>
        <p>
          Welcome to Shopstrat Development. These Terms of Use ("Terms") govern your use of our website and services. By accessing or using our website, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.
        </p>

        <h4>1. Acceptance of Terms</h4>
        <p>
          By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Statement. If you do not agree to these Terms, you may not access or use our website or services.
        </p>

        <h4>2. Use of the Website</h4>
        <p>
          You agree to use our website and services only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of our website complies with all applicable laws and regulations. You agree not to:
        </p>
        <ul>
          <li>Use our website in any manner that could damage, disable, overburden, or impair our servers or networks.</li>
          <li>Interfere with any other party's use and enjoyment of our website.</li>
          <li>Attempt to gain unauthorized access to any part of our website, other accounts, computer systems, or networks connected to our website.</li>
          <li>Use any automated means, such as robots or spiders, to access our website.</li>
        </ul>

        <h4>3. Intellectual Property</h4>
        <p>
          All content on our website, including text, graphics, logos, images, and software, is the property of Shopstrat Development or its content suppliers and is protected by intellectual property laws. You may not use, reproduce, modify, distribute, or display any content from our website without our prior written consent.
        </p>

        <h4>4. Limitation of Liability</h4>
        <p>
          Shopstrat Development is not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to, or use of, our website or services. This includes, but is not limited to, any errors or omissions in content, or any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via our website.
        </p>

        <h4>5. Governing Law</h4>
        <p>
          These Terms are governed by and construed in accordance with the laws of the Province of British Columbia, Canada. Any disputes arising out of or relating to these Terms or your use of our website or services will be resolved in the courts of British Columbia.
        </p>

        <h4>6. Changes to These Terms</h4>
        <p>
          We may update these Terms from time to time. The latest version will always be available on this page, and the date of the last update will be indicated at the top of the Terms. We encourage you to review these Terms periodically to stay informed about our terms and conditions.
        </p>

        <h4>7. Contact Us</h4>
        <p>
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <p>
          Shopstrat Development<br />
          PO Box 50026, 15-1594 Fairfield Rd<br />
          Victoria, BC V8S 1G0<br />
          <a href="mailto:info@shopstrat.dev">info@shopstrat.dev</a>
        </p>
      </div>
    </section>
  );
}

export default TermsOfUse;

