import React from 'react';
import '../index.css';
import { Link as RouterLink } from 'react-router-dom';
import SocialLinks from './SocialLinks';
// import FooterForm from './FooterForm';

const Footer = ({ setActiveSection, activeSection, handleNavigation, offset }) => {
  return (
    <footer id='footer' className='section alt-bg-solid fade-in'>
      <div className='section-content'>
        <div className='footer-content'>
          <div className='footer-column'>
            <RouterLink to="/" onClick={() => handleNavigation('hero', offset, true)}>
              <div className='footer-logo-container'>
                <h4>shopstrat_</h4>
              </div>
            </RouterLink>
            <p>Shopify strategy and development services tailored to your business needs.</p>
            <SocialLinks />
          </div>
          <div className='footer-column'>
            <div className='footer-column-heading'>
              <h5>Company</h5>
            </div>
            <div className='footer-links'>
              <ul>
                <RouterLink to="/" onClick={() => handleNavigation('about', offset)}>
                  <li className={`footer-link ${activeSection === 'about' ? 'active' : ''}`}>About Us</li>
                </RouterLink>
                <RouterLink to="/" onClick={() => handleNavigation('services', offset)}>
                  <li className={`footer-link ${activeSection === 'services' ? 'active' : ''}`}>Our Services</li>
                </RouterLink>
              </ul>
            </div>
          </div>
          <div className='footer-column'>
            <div className='footer-column-heading'>
              <h5>Help</h5>
            </div>
            <div className='footer-links'>
              <ul>
                <RouterLink to="/" onClick={() => handleNavigation('contact', offset)}>
                  <li className={`footer-link ${activeSection === 'contact' ? 'active' : ''}`}>Contact</li>
                </RouterLink>
                <RouterLink to="/" onClick={() => handleNavigation('start', offset)}>
                  <li className={`footer-link ${activeSection === 'start' ? 'active' : ''}`}>Start Here</li>
                </RouterLink>
                <RouterLink to="/" onClick={() => handleNavigation('faq', offset)}>
                  <li className={`footer-link ${activeSection === 'faq' ? 'active' : ''}`}>FAQ</li>
                </RouterLink>
              </ul>
            </div>
          </div>
          {/* <div className='footer-column'>
            <div className='footer-column-heading'>
              <p>Subscribe</p>
            </div>
            <p>Shopify tips and tricks delivered directly to your inbox.</p>
            <FooterForm />
          </div> */}
          <div className='footer-column'>
            <div className='footer-column-heading'>
              <h5>Legal</h5>
            </div>
            <div className='footer-links'>
              <ul>
                <RouterLink to="/terms-of-use">
                  <li className='footer-link'>Terms of Use</li>
                </RouterLink>
                <RouterLink to="/privacy-statement">
                  <li className='footer-link'>Privacy Statement</li>
                </RouterLink>
                <RouterLink to="/refund-policy">
                  <li className='footer-link'>Refund Policy</li>
                </RouterLink>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-copyright'>
          <p>© 2024 Shopstrat Development</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;




