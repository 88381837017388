import React from 'react';
import meCasual from '../assets/images/me-casual.jpg';

const About = ({ activeSection }) => {
 
  return (
    <section id="about" className='section fade-in'>
      <div className='section-content'>
        <div className='two-columns'>
          <div className='column'>
            <div className='about-image'>
              <img src={meCasual} alt='Curtis Hill' />
            </div>
          </div>
          <div className='column'>
            <div className='section-heading heading-left'>
              <h2 className={`${activeSection === 'about' ? 'heading-active' : ''}`}>
                Meet Curtis
              </h2>
            </div>
            <p>Curtis is the founder and lead strategist at Shopstrat. With over a decade of experience, he specializes in crafting high-performance Shopify stores. From launching new stores to optimizing existing ones, Curtis and his team deliver exceptional results that drive business growth.</p>
            <div className='personal-links'>
              <div className='personal-link'>
                <a href="https://www.upwork.com/freelancers/~011cb15a0a6b6fbfb2" target="_blank" rel="noopener noreferrer">
                  Upwork <i className="fas fa-external-link-alt"></i>
                </a>
              </div>
              <div className='personal-link'>
                <a href="https://www.linkedin.com/in/curtis90h/" target="_blank" rel="noopener noreferrer">
                  LinkedIn <i className="fas fa-external-link-alt"></i>
                </a>
              </div>
              <div className='personal-link'>
                <a href="https://github.com/curtis90h" target="_blank" rel="noopener noreferrer">
                  GitHub <i className="fas fa-external-link-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About