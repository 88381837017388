import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faGithub, faUpwork, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SocialLinks = () => {
  return (
    <div className='social-icons'>
      <a href="https://www.upwork.com/freelancers/~011cb15a0a6b6fbfb2" className='social-icon' target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faUpwork} />
      </a>
      <a href="https://www.linkedin.com/in/curtis90h/" className='social-icon' target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a href="https://github.com/curtis90h" className='social-icon' target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a href="https://youtube.com/@shopstratdev" className='social-icon' target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
    </div>
  );
};

export default SocialLinks;



