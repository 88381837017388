import React, { useState, useEffect } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    website: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setShowCheckmark(true);
      const timer = setTimeout(() => {
        setShowCheckmark(false);
      }, 2000); // Display checkmark for 2 seconds
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSubmitted(false);
    setError('');
    setIsSuccess(false);
  
    const startTime = Date.now();

    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const minLoadingTime = 3000; 
      const elapsedTime = Date.now() - startTime;
      const remainingTime = minLoadingTime - elapsedTime;

      setTimeout(() => {
        if (response.ok) {
          setSubmitted(true);
          setFormData({ firstName: '', lastName: '', email: '', website: '', message: '' });
          setIsSuccess(true);
        } else {
          setError('Failed to send your message. Please try again later.');
        }
        setIsLoading(false);
      }, remainingTime > 0 ? remainingTime : 0);

    } catch (error) {
      console.error('Error submitting form', error);
      setError('An error occurred. Please try again later.');
      setTimeout(() => {
        setIsLoading(false);
      }, 3000); // Ensure the loading time is respected even in case of error
    }
  };

  return (
    <form id="contact-form" onSubmit={handleSubmit}>
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />

      <div className="contact-form-input-row">
        <div className="contact-form-input-field half-width">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-form-input-field half-width">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="contact-form-input-field">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-form-input-field">
        <label htmlFor="website">Website</label>
        <input
          type="url"
          name="website"
          id="website"
          value={formData.website}
          onChange={handleChange}
        />
      </div>
      <div className="contact-form-input-field">
        <label htmlFor="message">Message</label>
        <textarea
          name="message"
          id="message"
          value={formData.message}
          onChange={handleChange}
          required
        />
      </div>
      <div className='submit-button-container'>
        <button className="submit-btn btn-retro btn-colored" type="submit" disabled={isLoading || showCheckmark}>
          {isLoading ? (
            <div className="loader-container">
              <div className="loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : showCheckmark ? (
            <i className="fas fa-check success-checkmark"></i>
          ) : (
            'Submit'
          )}
        </button>
        {submitted && !isLoading && <p className='contact-form-submit-message'>Thank you, we'll get back to you in 1-2 business days.</p>}
        {error && !isLoading && <p className='contact-form-submit-message error-message'>{error}</p>}
      </div>
    </form>
  );
};

export default ContactForm;

