import React, { useEffect } from 'react';

function RefundPolicy() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="refund" className="page fade-in">
      <div className='section-content'>
        <h2>Refund Policy</h2>
        <p>Last Updated: July 8th, 2024</p>
        <p>
          At Shopstrat Development, we are committed to providing high-quality Shopify consulting and development services. However, if you are not entirely satisfied with your purchase, we’re here to help.
        </p>

        <h4>1. Eligibility for Refunds</h4>
        <p>
          You may request a refund under the following conditions:
        </p>
        <ul>
          <li> - Dissatisfaction with services within reason.</li>
          <li> - Non-delivery of services.</li>
        </ul>

        <h4>2. Timeframe for Refund Requests</h4>
        <p>
          Refund requests must be submitted within 7 days after the delivery of services.
        </p>

        <h4>3. Refund Process</h4>
        <p>
          To request a refund, please reach out to us in writing via email at <a href="mailto:info@shopstrat.dev">info@shopstrat.dev</a> within the specified timeframe, providing the reasons for your request. We will review your claim and decide if it is valid. We reserve the right to reject any claims. If approved, the refund will be processed to the original payment method within 14 business days.
        </p>

        <h4>4. Non-Refundable Items/Services</h4>
        <p>
          There are no specific services or items that are non-refundable under this policy.
        </p>

        <h4>5. Processing Time</h4>
        <p>
          Approved refunds will be processed within 14 business days to the original payment method.
        </p>

        <h4>6. Contact Information</h4>
        <p>
          If you have any questions or concerns about this Refund Policy, please contact us via email at <a href="mailto:info@shopstrat.dev">info@shopstrat.dev</a>.
        </p>

        <h4>7. Changes to This Refund Policy</h4>
        <p>
          We may update this Refund Policy from time to time. The latest version will always be available on this page, and the date of the last update will be indicated at the top of the policy. We encourage you to review this policy periodically to stay informed about our terms and conditions regarding refunds.
        </p>
      </div>
    </section>
  );
}

export default RefundPolicy;

