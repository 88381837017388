import React from 'react';
import '../index.css';
import { Link as RouterLink } from 'react-router-dom';

const MobileMenu = ({ toggleMobileMenu, setActiveSection, activeSection, handleNavigation, offset }) => {
  const handleMenuNavigation = (section, noOffset = false) => {
    handleNavigation(section, offset, noOffset);
    toggleMobileMenu(); // Close the mobile menu after navigation
  };

  const zIndexMobileMenu = {
    zIndex: '5000'
  };

  return (
    <div className='mobile-menu fade-in' style={zIndexMobileMenu}>
      <RouterLink to="/" onClick={() => handleMenuNavigation('hero', true)}> {/* noOffset is true */}
        <h3 className='link'>
          shopstrat_
        </h3>
      </RouterLink>
      <ul className='mobile-nav'>
        <RouterLink to="/" onClick={() => handleMenuNavigation('about')}>
          <li className={`link ${activeSection === 'about' ? 'active' : ''}`}>About Us</li>
        </RouterLink>
        <RouterLink to="/" onClick={() => handleMenuNavigation('services')}>
          <li className={`link ${activeSection === 'services' ? 'active' : ''}`}>Our Services</li>
        </RouterLink>
        {/* <RouterLink to="/" onClick={() => handleMenuNavigation('partners')}>
          <li className={`link ${activeSection === 'partners' ? 'active' : ''}`}>Industry Partners</li>
        </RouterLink> */}
        <RouterLink to="/" onClick={() => handleMenuNavigation('contact')}>
          <li className={`link ${activeSection === 'contact' ? 'active' : ''}`}>Contact</li>
        </RouterLink>
        <RouterLink to="/" onClick={() => handleMenuNavigation('faq')}>
          <li className={`link ${activeSection === 'faq' ? 'active' : ''}`}>FAQ</li>
        </RouterLink>
      </ul>
      <RouterLink to="/" onClick={() => handleMenuNavigation('start')}>
        <button className="btn-alt btn-large">Start Here</button>
      </RouterLink>
    </div>
  );
}

export default MobileMenu;








