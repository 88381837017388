import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Element, Events, scrollSpy } from 'react-scroll';
import Header from './components/Header';
import Hero from './components/Hero';
import Banner from './components/Banner';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Start from './components/Start';
import Footer from './components/Footer';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyStatement from './pages/PrivacyStatement';
import RefundPolicy from './pages/RefundPolicy';
import NavigationHandler from './components/NavigationHandler';

function App() {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');

  const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'), 10);

  const handleScroll = () => {
    if (window.scrollY > headerHeight / 4) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  useEffect(() => {
    Events.scrollEvent.register('begin', () => {});
    Events.scrollEvent.register('end', (to) => {
      setActiveSection(to);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <Router>
      <div className='app'>
        <NavigationHandler setActiveSection={setActiveSection}>
          {(handleNavigation) => (
            <>
              <Header
                isSticky={isSticky}
                toggleMobileMenu={toggleMobileMenu}
                isMobileMenuVisible={isMobileMenuVisible}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                handleNavigation={handleNavigation}
                offset={headerHeight * -1}
              />
              <Routes>
                <Route path="/" element={
                  <>
                    <Element name="hero">
                      <Hero activeSection={activeSection} setActiveSection={setActiveSection} handleNavigation={handleNavigation} />
                    </Element>
                    <Banner />
                    <Element name="about">
                      <About activeSection={activeSection} />
                    </Element>
                    <Element name="services">
                      <Services activeSection={activeSection} />
                    </Element>
                    {/* <Element name="partners">
                      <Partners activeSection={activeSection} />
                    </Element> */}
                    <Element name="contact">
                      <Contact activeSection={activeSection} />
                    </Element>
                    <Element name="start">
                      <Start activeSection={activeSection} />
                    </Element>
                    <Banner />
                  </>
                } />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/privacy-statement" element={<PrivacyStatement />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
              </Routes>
              <Footer activeSection={activeSection} setActiveSection={setActiveSection} handleNavigation={handleNavigation} offset={headerHeight * -1} />
            </>
          )}
        </NavigationHandler>
      </div>
    </Router>
  );
}

export default App;


