import React from 'react';
import dottedMap from '../assets/images/dotted-map.png';

const Contact = ({ activeSection }) => {
  return (
    <section id="contact" className='section fade-in'>
      <div className='section-content'>
        <div className='two-columns'>
          <div className='column'>
            <div className='section-heading heading-center'>
              <h2 className={`${activeSection === 'contact' ? 'heading-active' : ''}`}>
                Contact Us
              </h2>
              <p>Need help with your Shopify store?</p>
              <p>Contact us by email, phone, or through our online form. We'll get back to you within 1-2 business days.</p>
            </div>
          </div>
          <div className='column'>
            <div className='map-image'>
              <img src={dottedMap} alt='Dotted World Map' />
              <div className='pulsing-dot'>
                <div className='solid-dot'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='contact-methods'>
          <div className='contact-method'>
            <i className="fas fa-map-marker-alt contact-icon"></i>
            <a href="https://www.google.com/maps/dir/?api=1&destination=Victoria,+BC" target="_blank" rel="noopener noreferrer">Victoria, BC</a>
          </div>
          <div className='contact-method'>
            <i className="fas fa-envelope contact-icon"></i>
            <a href="mailto:info@shopstrat.dev">info@shopstrat.dev</a>
          </div>
          <div className='contact-method'>
            <i className="fas fa-phone contact-icon"></i>
            <a href="tel:+17782694747">+1 (778) 269-4747</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;