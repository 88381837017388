import React from 'react';

const Banner = () => {

  return (
    <section id="banner" className='section'>
      <div className='banner'>
        <div className='banner-content'>
          <div className='banner-text'>
            <h5>100%</h5>
            <p>Success Rate</p>
            <p>on Upwork</p>
          </div>
          <div className='banner-text'>
            <h5>10+</h5>
            <p>Years Experience</p>
            <p>in Ecommerce</p>
          </div>
          <div className='banner-text'>
            <h5>100%</h5>
            <p>Satisfaction</p>
            <p>Guarantee</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner;